import * as React from "react"
import { Helmet } from "react-helmet";
import { Navigation, Content, Details, PageHeading } from "../components";
import "../styles/main.scss";



// --------------------------------------------------------------------------------
// Intro
// --------------------------------------------------------------------------------
function PageIntroduction(): JSX.Element {
    const block1 = <>
        <div className="px-0 py-4">
            {/* <img src={people} className="my-0 mb-4" width="100%" height="40x" alt="" aria-label="Home" /> */}
            {/* <h2>Data collection</h2> */}
            <ul>
                <li>Pre-calculated HR metrics, ready-made stakeholder reports, in-built HR dashboards and reports.</li>
                <li>Easily identify patterns, trends and problems with a library of graphs and chart types.</li>
                <li>Works with your existing HR systems.</li>
            </ul>
        </div>
    </>;

    const block2 =
        <div className="px-0 py-4">
            {/* <img src={flag} className="my-0 mb-4" width="100%" height="40x" alt="" aria-label="Home" /> */}
            {/* <h2>Transformation</h2> */}
            <ul>
                <li>Schedule BAU reports and respond effectively to common HR data requests.</li>
                <li>Assess employee spend v ROI.</li>
                <li>Forecast staffing requirements and plot organisation change.</li>
            </ul>
        </div>;

    const block3 = <>
        <div className="px-0 py-4">
            {/* <img src={map} className="my-0 mb-4" width="100%" height="40x" alt="" aria-label="Home" /> */}

            {/* <h2>Presentation</h2> */}
            <ul>
                <li>Undertake scenario planning and compare what-if options.</li>
                <li>Analyse your plans and predict outcomes.</li>
                <li>Provide senior stakeholders with regular change management reports.</li>
                <li>Identify required headcount targets and costs.</li>
            </ul>
        </div>
    </>;

    return <div className="row row-cols-1 row-cols-sm-3">
        <div className="col qbl-content px-4">
            {block1}
        </div>
        <div className="col qbl-content px-4">
            {block2}
        </div>
        <div className="col qbl-content px-4">
            {block3}
        </div>
    </div>;
}
// --------------------------------------------------------------------------------
// Features
// --------------------------------------------------------------------------------
function PageFeatures(): JSX.Element {
    // How we transform data into insight

    const block1 = <>
        <div className="px-0 py-4">
            {/* <img src={people} className="my-0 mb-4" width="100%" height="40x" alt="" aria-label="Home" /> */}
            <h2>Data collection</h2>
            <p>Use existing Excel spreadsheets, other data files (such as CSVs), exports from your other software, or direct connections to your existing applications and databases.</p>
            <p>Combine HR, payroll, finance, attendance, performance, survey data and more.</p>
        </div>
    </>;

    const block2 =
        <div className="px-0 py-4">
            {/* <img src={flag} className="my-0 mb-4" width="100%" height="40x" alt="" aria-label="Home" /> */}
            <h2>Transformation</h2>
            <p>
                Qebal automatically transforms, classifies and aggregates your data
                to create the fundamental metrics, consistently over time and from
                different sources.
            </p>
            <p>
                Configuration options are available to control the transformation if required.
            </p>
        </div>;

    const block3 = <>
        <div className="px-0 py-4">
            {/* <img src={map} className="my-0 mb-4" width="100%" height="40x" alt="" aria-label="Home" /> */}

            <h2>Presentation</h2>
            <p>
                Interactive dashboards and reports help visualise the current state and historical
                trends. Uncover the detail behind the summaries by drilling into key metrics
                to explore patterns.
            </p>
            <p>
                Reports can be exported (for example to PDF) to help you communicate your stories.
            </p>
        </div>
    </>;

    const block4 = <>
        <div className="px-0 py-4">
            {/* <img src={map} className="my-0 mb-4" width="100%" height="40x" alt="" aria-label="Home" /> */}

            <h2>Data collection</h2>
            <p>
                Use existing Excel spreadsheets, other data files (such as CSVs),
                exports from your other software, or direct connections to your
                existing applications and databases.
            </p>
            <p>
                Combine HR, payroll, finance, attendance, performance, survey data and more.
            </p>
        </div>
    </>;

    return <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-4">
        <div className="col qbl-block-1 px-4">
            {block1}
        </div>
        <div className="col qbl-block-1 px-4">
            {block2}
        </div>
        <div className="col qbl-block-1 px-4">
            {block3}
        </div>
        <div className="col qbl-block-1 px-4">
            {block4}
        </div>
    </div>;
}

// --------------------------------------------------------------------------------
// Features
// --------------------------------------------------------------------------------
function PagePlatform(): JSX.Element {
    // Plaform Technology and Security

    const block1 = <>
        <div className="px-0 py-4">
            {/* <img src={people} className="my-0 mb-4" width="100%" height="40x" alt="" aria-label="Home" /> */}
            <h2>Your HR data lake</h2>
            <p>
                Qebal provides a consistent and unified view of your employee information
                using secure and efficient data storage technologies.
            </p><p>
                Our data lake deals with work that is easily forgotten - data cleansing,
                resolving missing data, improving data quality and reliable data processing.
            </p>
        </div>
    </>;

    const block2 =
        <div className="px-0 py-4">
            {/* <img src={flag} className="my-0 mb-4" width="100%" height="40x" alt="" aria-label="Home" /> */}
            <h2>User access</h2>
            <p>
                You decide who has access to your reports within your organisation.
            </p><p>
                Single sign on allows your users to access the platform with your existing organisation
                log in, allowing you to benefit from advanced security features such as multi-factor authentication
                without having a different username and password to remember.
            </p>
        </div>;

    const block3 = <>
        <div className="px-0 py-4">
            {/* <img src={map} className="my-0 mb-4" width="100%" height="40x" alt="" aria-label="Home" /> */}
            <h2>Fast and scalable</h2>
            <p>
                The user interface is modern, easy to use and responsive. Advanced reports can be
                generated easily and quickly.
            </p><p>
                We use Microsoft Azure and Amazon AWS to keep everything running efficiently, reliably
                and securely behind the scenes.
            </p>
        </div>
    </>;

    const block4 = <>
        <div className="px-0 py-4">
            {/* <img src={map} className="my-0 mb-4" width="100%" height="40x" alt="" aria-label="Home" /> */}
            <h2>Data security</h2>
            <p>
                Data is encrypted (in transit and at rest) and kept only for as long as required.
            </p><p>
                Automatic threat detection technology works to keep our infrastructure and code secure.
            </p><p>
                The application code behind our platform is regularly security tested, including
                automated vulnerability tests before every change is deployed.
            </p>
        </div>
    </>;

    return <div className="row row-cols-1 row-cols-sm-1">
        <div className="col qbl-content px-4">
            {block1}
        </div>
        <div className="col qbl-content px-4">
            {block2}
        </div>
        <div className="col qbl-content px-4">
            {block3}
        </div>
        <div className="col qbl-content px-4">
            {block4}
        </div>
    </div>;
}


// --------------------------------------------------------------------------------
// Intro
// --------------------------------------------------------------------------------
const introBody = [<>
    <p>
        Pre-calculated HR metrics, ready-made stakeholder reports, in-built HR dashboards and reports.
    </p>
    <p>
        Easily identify patterns, trends and problems with a library of graphs and chart types.
    </p>
    <p>
        Works with your existing HR systems.
    </p>
</>, <>
    <p>
        Schedule BAU reports and respond effectively to common HR data requests.
    </p><p>
        Assess employee spend v ROI.
    </p><p>
        Forecast staffing requirements and plot organisation change.
    </p>
</>, <>
    <p>
        Undertake scenario planning and compare what-if options.
    </p><p>
        Analyse your plans and predict outcomes.
    </p><p>
        Provide senior stakeholders with regular change management reports.
        Identify required headcount targets and costs.
    </p>
</>
];

const intro = <Details centred content={introBody} />;


// --------------------------------------------------------------------------------
// Key quote
// --------------------------------------------------------------------------------
// const keyQuoteBody = [<>
//     <p>
//         Qebal People Analytics transforms your HR data into reports and dashboards with key employee metrics.
//     </p>
// </>
// ];
// const keyQuote = <Details centred title="Make HR an ally of your business stakeholders" content={keyQuoteBody} />;


// --------------------------------------------------------------------------------
// Features
// --------------------------------------------------------------------------------
const processBody = [<>
    <h3>Data collection</h3>
    <p>
        Use existing Excel spreadsheets, other data files (such as CSVs),
        exports from your other software, or direct connections to your
        existing applications and databases.
    </p>
    <p>
        Combine HR, payroll, finance, attendance, performance, survey data and more.
    </p>
</>, <>
    <h3>Transformation</h3>
    <p>
        Qebal automatically transforms, classifies and aggregates your data
        to create the fundamental metrics, consistently over time and from
        different sources.
    </p>
    <p>
        Configuration options are available to control the transformation if required.
    </p>
</>, <>
    <h3>Presentation</h3>
    <p>
        Interactive dashboards and reports help visualise the current state and historical
        trends. Uncover the detail behind the summaries by drilling into key metrics
        to explore patterns.
    </p>
    <p>
        Reports can be exported (for example to PDF) to help you communicate your stories.
    </p>
</>, <>
    <h3>Housekeeping</h3>
    <p>
        Data management tasks are automated behind the scenes to ensure the data is up to date,
        accurate, and retained for only as long as needed.
    </p>
</>
];
const process = <Details titleCentred title="How we transform data into insight" content={processBody} />;


// --------------------------------------------------------------------------------
// Features
// --------------------------------------------------------------------------------
const securityBody = [<>
    <h3>Your HR data lake</h3>
    <p>
        Qebal provides a consistent and unified view of your employee information
        using secure and efficient data storage technologies.
    </p><p>
        Our data lake deals with work that is easily forgotten - data cleansing,
        resolving missing data, improving data quality and reliable data processing.
    </p>
</>, <>
    <h3>User access</h3>
    <p>
        You decide who has access to your reports within your organisation.
    </p><p>
        Single sign on allows your users to access the platform with your existing organisation
        log in, allowing you to benefit from advanced security features such as multi-factor authentication
        without having a different username and password to remember.
    </p>
</>, <>
    <h3>Fast and scalable</h3>
    <p>
        The user interface is modern, easy to use and responsive. Advanced reports can be
        generated easily and quickly.
    </p><p>
        We use Microsoft Azure and Amazon AWS to keep everything running efficiently, reliably
        and securely behind the scenes.
    </p>
</>, <>
    <h3>Data security</h3>
    <p>
        Data is encrypted (in transit and at rest) and kept only for as long as required.
    </p><p>
        Automatic threat detection technology works to keep our infrastructure and code secure.
    </p><p>
        The application code behind our platform is regularly security tested, including
        automated vulnerability tests before every change is deployed.
    </p>
</>
];
const security = <Details titleCentred title="Plaform Technology and Security" content={securityBody} />;


// --------------------------------------------------------------------------------
// Footnote
// --------------------------------------------------------------------------------
const footnoteBody = [<>
    <h2>
        Move from opinion to evidence based decision making with insightful dashboards and reports.
    </h2>
</>
];
const footnote = <Details centred content={footnoteBody} />;

//
// --------------------------------------------------------------------------------
//

const PeopleAnalyticsPlatform = () => {
    return <>
        <Helmet title="Qebal / People Analytics Platform" htmlAttributes={{ lang: 'en' }} />
        <Navigation />
        <Content>
            <div className="container-lg">
                <PageHeading centred title="The People Analytics Platform" />
                <PageIntroduction />
            </div>

            <div className="qbl-block-1">
                <div className="container-lg">
                    <PageFeatures />
                </div>
            </div>

            <div className="container-lg">
                <PagePlatform />
            </div>
        </Content>
    </>
};

export default PeopleAnalyticsPlatform;